<template>
  <div style="padding-top:60px">
    <div class="banner">
      <div class="banner-btn"
           @click="LiJiTiyan"></div>
    </div>
    <div class="gj-box">
      <div class="center-box">
        <div class="content-title">
          <div>海豚管家功能介绍</div>
          <div>革新云服务器，解决商家痛点</div>
        </div>
        <div class="gj-list"
             v-for="(item,index) in gjList"
             :key="index">
          <div :class="index % 2 == 0?'float-l':'float-r'">
            <div class="gj-title">{{item.title}}</div>
            <div class="gj-desc"
                 v-for="(dec,i) in item.listDesc"
                 :key='i'>{{dec}}</div>
            <div class="gj-btn"
                 @click="LiJiTiyan">立即下载</div>
          </div>
          <div :class="['gj-img',index % 2 == 0?'float-r':'float-l']">
            <img :src="item.imgUrl"
                 alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="wait-tips">更多功能开发中 请持续关注...</div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      gjList: [
        {
          title: '云服务管理',
          imgUrl: require('../assets/images/md/fuwuguanli.png'),
          listDesc: [
            '支持多个电商平台，不限数量；',
            '一店一IP，干净稳定，安全好用；',
            '关联性弱，无需远程；',
            '完美解决店群店铺关联处罚问题；',
          ],
        },
        {
          title: '聊天台',
          imgUrl: require('../assets/images/md/liaotiantai.png'),
          listDesc: [
            '支持多店铺聚合聊天；',
            '机器人智能回复；',
            '聚合聊天可独立使用',
          ],
        },
        {
          title: '供应链售后管理',
          imgUrl: require('../assets/images/md/gongyinlian.png'),
          listDesc: [
            '支持多店铺、多供应链自动售后；',
            '支持自动推送售后表格至供应链；',
          ],
        }
      ],
    }
  },
  methods: {
    LiJiTiyan () {
      this.$router.push('/download')
      this.setSessionItem('routerName', '/download')
    },
  }
}
</script>

<style lang="less" scoped>
.gj-list {
  margin-bottom: 180px;
}
</style>
